@import "bootstrap/scss/offcanvas";

ngb-offcanvas-panel {
  overflow-y: auto;
}

.offcanvas-header {
  justify-content: space-between;

  h4 {
    font-size: 1.25rem;
  }
}

.offcanvas-body {

  .primary-content {
    border-bottom: 1px solid $border-color;
    font-size: 1rem;
    padding: .5rem 1rem;
    margin: 0;

    fa-icon {
      font-size: .8em;
    }

    small {
      font-weight: 400;
    }
  }

  table {

    th {
      color: $temp-light;
    }

    td, th {
      border-bottom: 1px solid $border-color;
    }

    th, td {
      border-top: 0;
      padding: 1rem .5rem;
    }
  }
}
