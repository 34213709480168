@import "bootstrap/scss/functions";

$assets-path: '/angular_assets/images';

// Color Pallete
$blue: #2499EF;
$light-blue: #A8B5F5;
$black: #000000;
$teal: #2AC9CC;
$red: #F54931;
$cyan: #00858E;
$yellow: #f2b01e;
$green: #4BAE44;
$orange: #FE9615;
$purple: #6E3FCF;
$indigo: #6078ee;
$pink: #E02C80;
$white: #fff;

$primary: $indigo;
$secondary: $green;
$info: $blue;
$danger: $red;
$dark: hsla(237, 15%, 25%, 1);
$medium: hsla(237, 15%, 40%, 1);
$medium-disabled: hsla(237, 15%, 40%, .5);
$promo-color: #F55F5C;
$gray: #D2D6DF;
$gray-blue: rgba(230, 236, 245, 1);
$gray-blue-light: #e7f1f8;
$gray-light: #F2F5FA;
$gray-medium: #6a7992;
$new-gray: #737380;
$message-color: $purple;
$message-color-line: $purple;

// Temporary Bootstrap Variables for Migration
$temp-light: #7b7d9d;

$colors: (
  dark:       $dark,
  error:      $danger,
  gray-light: $gray-light,
  green:      $green,
  promo:      $promo-color,
  teal:       $teal,
);

//Link Styles
$link-color: $primary;
$link-hover-color: darken($link-color, 15%);

//Card Styles
$card-border-radius: 6px;
$card-border-color: rgba($black, .055);
$card-disabled-color: #F3F3F5;
$card-disabled-bg: rgba(255, 255, 255, 0.72);

//Button Styles
$btn-color: $white;
$btn-box-shadow: 0px 6px 15px 0px rgba($black, 0.15);
$btn-focus-box-shadow: 4px 4px 15px rgba($black, 0.3);
$btn-active-box-shadow: $btn-focus-box-shadow;
$btn-border-radius: 5px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-line-height: 2rem;
$btn-line-height-sm: $btn-line-height;


//Navbar
$navbar-box-shadow: 0.5px 0.598px 0px #D2D6DF;

//Text Styles
$body-color: $dark;
$font-size-base: 1rem;
$font-family-base: 'Rubik', 'Open Sans' ,-apple-system, 'Helvetica Neue', 'Roboto', sans-serif;
$headings-font-weight: bold;
$h1-font-size: $font-size-base * 3.25;
$h2-font-size: $font-size-base * 2.25;
$h3-font-size: $font-size-base * 2;
$headings-line-height: 1.44;

//Input Styles
$input-border-color: $gray;
$input-focus-border-color: $primary;
$input-focus-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
$input-border-radius: 6px;
$input-border-radius-lg: $input-border-radius;
$input-border-radius-sm: $input-border-radius;
$input-btn-focus-box-shadow: $input-focus-box-shadow;
$input-btn-line-height: 2.25rem;
$input-line-height: 1.5;
$input-btn-padding-y: .375rem;
$input-btn-padding-x: .75rem;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$input-padding-y: 0.75rem;
$input-padding-x: 0.75rem;


//Form Styles
$form-group-margin-bottom: 1.2rem;
$form-group-height: 63px;

//Select Styles
$custom-select-padding-y: $btn-padding-y;
$custom-select-padding-x: $btn-padding-x;
$custom-select-line-height: $input-btn-line-height;
$custom-select-focus-box-shadow: $input-focus-box-shadow;

//Accordion Styles
$accordion-bg: transparent;
$accordion-border-width: 0;

//Dropdown Styles
$dropdown-min-width: 100%;
$dropdown-border-radius: $input-border-radius !important;

//Badge Styles
$badge-font-size: 13px;
$badge-font-weight: 500;
$badge-padding-y: .25em;
$badge-padding-x: .7em;
$border-radius: .375rem !default;
$badge-border-radius: $border-radius !default;
$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$badge-transition: $btn-transition !default;
$focus-ring-width: .25rem !default;
$input-btn-focus-width: $focus-ring-width !default;
$badge-focus-width: $input-btn-focus-width !default;
$badge-pill-padding-x: .7em;
$badge-pill-border-radius: 10rem !default;

//Offcanvas
$offcanvas-horizontal-width: 499px;

//Floating Labels
$form-floating-label-transform: translateY(-.5rem);

//Mixins
@mixin primary-underline {
  &::after {
    content: '';
    border-bottom: 3px solid $primary;
    width: 40px;
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
  }
}

@mixin ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin marker-bubble($bg-color, $border-color) {
  background-color: $bg-color;
  border-radius: 7px;
  filter: drop-shadow(0px 0px 1px $border-color);

  &::after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    margin: auto;
    border-bottom: 0;
    border-left: 6px solid transparent;
    border-top: 8px solid $bg-color;
    border-right: 6px solid transparent;
  }

  @media all and (-ms-high-contrast: none) {
    // IE fallback (filter drop-shadow is not supported)
    box-shadow: 0 0 1px 1px rgba($border-color, .25);
  }
}

@mixin separator($color) {
  &:after {
    content: '';
    position: absolute;
    top: 20px;
    bottom: 20px;
    right: 0;
    width: 1px;
    background-color: $color;
  }
}

//Utilites
$min-contrast-ratio: 2.5;

@import "bootstrap/scss/variables";

$theme-colors: (
  "danger":     $danger,
  "dark":       $dark,
  "gray":       $gray-medium,
  "info":       $info,
  "light":      $light,
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "warning":    $warning,
);

// @import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/mixins";
