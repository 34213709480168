/* Bounce Animation
===================================*/
@-webkit-keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }
  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  /* FadeIn Animation
  ===================================*/
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation-name: fadeIn;
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInDown {
    animation-name: fadeInDown;
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInUp {
    animation-name: fadeInUp;
  }

  /* General Animations
  ===================================*/
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  .animated.infinite {
    animation-iteration-count: infinite;
  }
  .animated.delay-1s {
    animation-delay: 1s;
  }
  .animated.delay-2s {
    animation-delay: 2s;
  }
  .animated.delay-3s {
    animation-delay: 3s;
  }
  .animated.delay-4s {
    animation-delay: 4s;
  }
  .animated.delay-5s {
    animation-delay: 5s;
  }
  .animated.fast {
    animation-duration: 800ms;
  }
  .animated.faster {
    animation-duration: 500ms;
  }
  .animated.slow {
    animation-duration: 2s;
  }
  .animated.slower {
    animation-duration: 3s;
  }
  .animated.once {
    animation-iteration-count: 1;
  }
  @media (prefers-reduced-motion) {
    .animated {
      animation: unset !important;
      transition: none !important;
    }
  }
  @-webkit-keyframes floatUpDown {
    from    {-webkit-transform: translate(0, 0px);}
    65%     {-webkit-transform: translate(0, 18px);}
    to      {-webkit-transform: translate(0, -0px);}
  }
  @-moz-keyframes floatUpDown {
    from    {-moz-transform: translate(0, 0px);}
    65%     {-moz-transform: translate(0, 20px);}
    to      {-moz-transform: translate(0, -0px);}
  }
  @keyframes floatUpDown {
    from    {transform: translate(0, 0px);}
    65%     {transform: translate(0, 18px);}
    to      {transform: translate(0, -0px);}
  }
  .floatUpDown {
    animation-name: floatUpDown;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-delay: initial;
    animation-iteration-count: infinite;
    animation-direction: initial;
    animation-fill-mode: initial;
    animation-play-state: initial;
  }
  @keyframes pulse {
    from {
      transform: scale3d(1, 1, 1);
    }

    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }
  .pulse {
    animation-name: pulse;
    animation-duration: 0.25s;
    animation-iteration-count: 1;
  }

  /* Animated Loading Indicator
=================================== */
.card-loading-indicator {
    padding: 16px;
    .animation-line {
      @extend .animated-background;
      margin: 4px 0px;
      display: block;
      margin-bottom: 10px;
    }
    .height-56 {
      max-height: 56px;
    }
    .height-50 {
      max-height: 50px;
    }
    .height-35 {
      max-height: 35px;
    }
    .summary {
      max-height: 24px;
    }
    .guest-info {
      display: inline-block;
      max-height: 16px;
    }
    .line-items {
      display: block;
      max-height: 20px;
      margin-bottom: 20px;
    }
    @keyframes placeHolderShimmer {
      0% {
        background-position: -468px 0
      }
      100% {
        background-position: 468px 0
      }
    }
    .animated-background {
      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #F6F6F6;
      background: linear-gradient(to right, #f0f0f0 8%, #e6ecf5 18%, #f0f0f0 33%);
      background-size: 800px 104px;
      height: 96px;
      position: relative;
    }
  }
  .show-1385-up {
    @media (min-width: 1385px) {
      display: block !important;
    }
  }
  .show-1384-down {
    @media (max-width: 1384px) {
      display: flex !important;
    }
  }
  .show-between-992-1384 {
    @media (min-width: 992px) and (max-width: 1384px) {
      display: flex !important;
    }
  }